import $ from 'jquery';

window.jQuery = $;
window.$ = $; // lazy fix if you want to use jQuery in your inline scripts.

//button up
$(document).on('turbolinks:load', function() {
  $(window).scroll(function() {
    if($(this).scrollTop() == 0) {
      $('#toTop').fadeOut();
    } else {
      $('#toTop').fadeIn();
    }
  });

  $('#toTop').click(function(e) {
    e.preventDefault();
    $('body,html').animate({scrollTop: 0}, 800);
  });

  // PhotoSwipe
  let items = [];
  let pswpElement = $('.pswp')[0];

  $('#gallery figure').each(function(){
    let _link = $(this).find('a'),
      item = {
        src: _link.attr('href'),
        w: _link.data('width'),
        h: _link.data('height'),
        title: _link.data('caption')
      };
    items.push(item);
  });

  $('#gallery a').click(function(event){
    event.preventDefault();

    let options = {
      index: $(this).closest('figure').index(),
      bgOpacity: 0.85,
      showHideOpacity: true,
      shareEl: false
    };
    // Initialize PhotoSwipe
    let gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
    gallery.init();
  });

  if ( window.location.hash == "#nav-documents" ) {
    $(".nav-item.nav-link").removeClass('active');
    $("a[href='#nav-documents']").addClass('active');
    $(".tab-pane").removeClass('active');
    $("#nav-documents").addClass('active');
    $('body,html').animate({scrollTop: 0}, 400);
  }

  if ( $(".newsletters table").length ) {
    $(".newsletters table").each((i, el) => {
      $(el).addClass("table table-hover");
    });
  }

  $('figure iframe').parent('figure').css('text-align', 'center');
});
